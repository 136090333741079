import React from "react";

const Certified = () => {
  return (
    <div className="certified" id="certified">
      <div className="certified-top-header">
        <img src="/assets/certificate.png" width={25} height={25} />
        <div className="certified-fnt-lg">We certified on</div>
      </div>
      <article className="heading">
        <div className="certified-headerfnt">Advanced IT Solutions Expert.</div>
        <p className="subtitle">
          Mastering Cutting-Edge Technologies for Seamless Business Operations
        </p>
      </article>
      <div>
        <a
          href="https://www.upwork.com/o/companies/~013e660b3ab5a55e07/"
          target="_blank"
          className="clr-primary"
        >
          <div className="certified-border">
            <div className="certified-image">
              <img
                src="/assets/upwork-badge.png"
                width={200}
                height={200}
                alt="upwork"
              />
            </div>
            <div className="certified-content">
              We presently boast top ratings on Upwork, a result of our
              unwavering commitment to excellence. Our achievements on Upwork
              are a testament to our proficiency and success in delivering
              outstanding services, consistently meeting and exceeding client
              expectations.
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Certified;
