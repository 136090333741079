import React, { useEffect, useState } from "react";
import { ServicesData } from "../../assets/data/services";
import serviceIcon from "../../assets/images/service-100.png";

const Services = ({ reference, selectedService }) => {
  const [selectedServiceData, setSelectedServiceData] = useState({});

  function changeSelectedService(id) {
    const data = ServicesData.filter((item) => {
      return item.id === id;
    });
    if (data && data.length > 0) {
      setSelectedServiceData(data[0]);
    } else {
      setSelectedServiceData(ServicesData[0]);
    }
  }
  useEffect(() => {
    changeSelectedService(selectedService);
  }, [selectedService]);

  return (
    <>
      <div className="services" id="services" ref={reference}>
        <div className="service-top-header">
          <img src={serviceIcon} width={25} height={25} />
          <div className="service-fnt-lg">Services</div>
        </div>
        <div className="heading">
          <div className="service-headerfnt">
            Empower your digital presence with Particle Infotech&#39;s
            specialised services.
          </div>
          <p className="service-headertext">
            We excel in crafting captivating mobile experiences, stunning UI/UX
            design, and robust backend and front-end development solutions.
            Elevate your brand's technology quotient with our expertise.
          </p>
        </div>
        <div className="container">
          <div className="left">
            <div>
              {ServicesData.map((item, index) => (
                <div
                  key={index}
                  className={` mob-techno ${
                    selectedServiceData.id === item.id ? "mob-selected" : ""
                  }`}
                >
                  <div
                    className={`techno  ${
                      selectedServiceData.id === item.id ? "selected" : ""
                    }`}
                    onClick={() => changeSelectedService(item.id)}
                    onMouseEnter={() => changeSelectedService(item.id)}
                  >
                    <div className="left-flx">
                      <img src={item.image} width={48} height={48} />
                      <p className="fnt-clr">
                        {item.name}
                        <br></br>
                        {item.name2}
                      </p>
                    </div>
                    <div className="left-num">{item.number}</div>
                  </div>
                  <div className="mob-description">
                    <h1 className="mob-title-service">
                      {selectedServiceData.title}
                    </h1>
                    <p className="mob-description-service">
                      {selectedServiceData.description}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        columnGap:"12px",
                        paddingTop: "25px",
                        flexWrap: "wrap",
                        rowGap: "12px"
                      }}
                    >
                      {selectedServiceData.mob_icons?.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "8px",
                            gap: "1rem",
                          }}
                        >
                          <div>{item.icon}</div>
                          <div>
                            <h2>{item.name}</h2>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="right">
            <article>
              <h1>{selectedServiceData.title}</h1>
              <p>{selectedServiceData.description}</p>

              {selectedServiceData.icons && <h3>Technologies</h3>}
              <div className="tech-stack">
                {selectedServiceData.icons?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "10px",
                      gap: "1rem",
                    }}
                  >
                    <div>{item.icon}</div>
                    <div>
                      <h2>{item.name}</h2>
                    </div>
                  </div>
                ))}
              </div>
            </article>
          </div>
        </div>
      </div>
      <div className="border-wd"></div>
    </>
  );
};

export default Services;
