import React, { useEffect, useRef, useState } from "react";
import { CountUp } from "use-count-up";
import breifcase from "../../assets/images/briefcase-96.png";

const MyComponent = ({ end, duration, decimalPlaces = 0 }) => (
  <CountUp
    isCounting
    end={end}
    duration={duration}
    decimalPlaces={decimalPlaces}
  >
    {({ value }) =>
    
      value < 2 || value == 10 ? <h1>{value}+</h1> : <h1>{value}+</h1>
    }
  </CountUp>
);

const Reasons = () => {
  const [showCounters, setShowCounters] = useState(false);
  const counterRef = useRef();
  
  useEffect(() => {
    const scrollEvent = () => {
      if (!showCounters) {
        const temp =
          window.scrollY + 350 > counterRef.current.getBoundingClientRect().top   ;
        setShowCounters(temp);
      }
    };
    window.addEventListener("scroll", scrollEvent);
    return () => window.removeEventListener("scroll", scrollEvent);
  }, []);
  return (
    <>
    <section className="max-wd" id="reasons">
      <div ref={counterRef} className="reasons"  name="reasons">
        <div className="top-header">
          <img src={breifcase} width={40} height={40} />
          <div className="fnt-lg">Particle Infotech</div>
        </div>
        <article>
          <p>Reasons why we are the right choice for your online success</p>
        </article>
        <div className="pointers">
          <div>
            <ul>
              <li className="list-space">
                Young and Talented Certified Developers
              </li>

              <li className="mob-spc"> Transparent Development Process</li>
            </ul>
          </div>
          <div>
            <ul>
              <li className="list-space"> Customer Centric Approach</li>

              <li className="mob-fnt"> Budget Friendly and On Time Deliveries</li>
            </ul>
          </div>
        </div>

        <article className="art-wd">
          {showCounters ? (
            <div className="mob-flex">
              <MyComponent isCounting end={5} decimalPlaces={0} duration={2}>
                {({ countUpRef }) => <h1 ref={countUpRef} />}
              </MyComponent>
              <div>
                <div className="fnt-xs">Years Experience</div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {showCounters ? (
            <div className="mob-flex">
              <MyComponent isCounting end={15} decimalPlaces={0} duration={2}>
                {({ countUpRef }) => <h1 ref={countUpRef} />}
              </MyComponent>
              <div>
                <div className="fnt-xs">Web Projects</div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {showCounters ? (
            <div className="mob-flex">
              <MyComponent isCounting end={20} decimalPlaces={0} duration={2}>
                {({ countUpRef }) => <h1 ref={countUpRef} />}
              </MyComponent>
              <div>
                <div className="fnt-xs">App Projects</div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {showCounters ? (
            <div className="mob-flex">
              <MyComponent isCounting end={12} decimalPlaces={0} duration={2}>
                {({ countUpRef }) => <h1 ref={countUpRef} />}
              </MyComponent>
              <div>
                <div className="fnt-xs">Developers</div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </article>
      </div>
    </section>
    <div className="border-wd"></div>
    </>
  );
};

export default Reasons;
