import FrontendImage from "../images/front-end80.png";
import BackendImage from "../images/Backend.png";
import MobileImage from "../images/Mobile.png";
import TestingImage from "../images/Testing.png";
import UIUXImage from "../images/UIUX.png";
import html from "../images/html.png";
import css from "../images/css.png";
import react from "../images/react.png";
import Vue from "../svg/vue.svg";
import angular from "../svg/angular.svg";
import figma from "../svg/figma.svg";
import canva from "../svg/canva.svg";
import ios from "../images/ios.png";
import android from "../svg/Android.svg";
import flutter from "../svg/flutter.svg";
import ionic from "../svg/ionic.svg";
import Java from "../svg/Java.svg";
import springBoot from "../svg/spring-boot.svg";
import python from "../svg/python.svg";
import laravel from "../images/laravel.png";
import php from "../svg/php.svg";
import mysql from "../svg/mysql.svg";
import SiPostgresql from "../svg/postgresql.svg";

export const ServicesData = [
  {
    id: 1,
    name: "Front-End",
    name2: "Development",
    number: "01",
    title: "Front-End Development",
    description:
      "It is the process of designing and building the graphical user interface of a website, wherein interactive elements like buttons, text boxes, search bar and more are created using HTML, CSS and JavaScript. A front end developer assures that the design of the website is accessible and less confusing which allows the visitor to use the website efficiently.",
    image: FrontendImage,
    icons: [
      {
        name: "HTML5",
        icon: <img src={html} width={35} height={35} />,
      },
      {
        name: "CSS3",
        icon: <img src={css} width={40} height={40} />,
      },
      {
        name: "React js",
        icon: <img src={react} width={40} height={40} />,
      },
      {
        name: "Vue js",
        icon: <img src={Vue} width={40} height={40} />,
      },
      {
        name: "Angular js",
        icon: <img src={angular} width={40} height={40} />,
      },
    ],
    mob_icons: [
      {
        name: "HTML5",
        icon: <img src={html} width={20} height={20} />,
      },
      {
        name: "CSS3",
        icon: <img src={css} width={20} height={20} />,
      },
      {
        name: "React js",
        icon: <img src={react} width={20} height={20} />,
      },
      {
        name: "Vue js",
        icon: <img src={Vue} width={20} height={20} />,
      },
      {
        name: "Angular js",
        icon: <img src={angular} width={20} height={20} />,
      },
    ],
  },

  {
    id: 2,
    name: "Back-End",
    name2: "Development",
    number: "02",
    title: "Back-End Development",
    description:
      "Back-end development includes the server side of a website, which makes the user interaction possible in the front-end by building and maintaining the systems. It includes databases, authentication/authorization features, application programming interfaces (APIs). Back-end developers majorly focus on data, creating the structure and implementing the back-end logic of a website by using back-end programming languages such as PHP, Laravel, and Java.",
    image: BackendImage,
    icons: [
      {
        name: "Java",
        icon: <img src={Java} width={40} height={40} />,
      },
      {
        name: "Spring Boot",
        icon: <img src={springBoot} width={40} height={40} />,
      },
      {
        name: "Python",
        icon: <img src={python} width={40} height={40} />,
      },
      {
        name: "Laravel",
        icon: <img src={laravel} width={40} height={40} />,
      },
      {
        name: "PHP",
        icon: <img src={php} width={40} height={40} />,
      },
      {
        name: "MySql",
        icon: <img src={mysql} width={40} height={40} />,
      },
      {
        name: "PostgreSQL",
        icon: <img src={SiPostgresql} width={40} height={40} />,
      },
    ],
    mob_icons: [
      {
        name: "Java",
        icon: <img src={Java} width={20} height={20} />,
      },
      {
        name: "Spring Boot",
        icon: <img src={springBoot} width={20} height={20} />,
      },
      {
        name: "Python",
        icon: <img src={python} width={20} height={20} />,
      },
      {
        name: "Laravel",
        icon: <img src={laravel} width={20} height={20} />,
      },
      {
        name: "PHP",
        icon: <img src={php} width={20} height={20} />,
      },
      {
        name: "MySql",
        icon: <img src={mysql} width={20} height={20} />,
      },
      {
        name: "PostgreSQL",
        icon: <img src={SiPostgresql} width={20} height={20} />,
      },
    ],
  },

  {
    id: 3,
    name: "Mobile Apps",
    name2: "Development",
    number: "03",
    title: "Mobile Apps Development",
    description:
      "It is the process of designing and developing software applications for smartphones and tablets. Most common operating systems used for development nowadays are Android and IOS. Mobile application development can be native or hybrid and it depends on the requirements of the application. Native apps are developed for a particular platform using the programming language and tools that are platform-specific. Hybrid apps use a single codebase that can function on either platform and are developed using a combination of native coding and web technologies.",
    image: MobileImage,
    icons: [
      {
        name: "iOS",
        icon: <img src={ios} width={40} height={40} />,
      },
      {
        name: "Android",
        icon: <img src={android} width={40} height={40} />,
      },
      {
        name: "Flutter",
        icon: <img src={flutter} width={40} height={40} />,
      },
      {
        name: "React Native",
        icon: <img src={react} width={40} height={40} />,
      },
      {
        name: "Ionic",
        icon: <img src={ionic} width={40} height={40} />,
      },
    ],
    mob_icons: [
      {
        name: "iOS",
        icon: <img src={ios} width={20} height={20} />,
      },
      {
        name: "Android",
        icon: <img src={android} width={20} height={20} />,
      },
      {
        name: "Flutter",
        icon: <img src={flutter} width={20} height={20} />,
      },
      {
        name: "React Native",
        icon: <img src={react} width={20} height={20} />,
      },
      {
        name: "Ionic",
        icon: <img src={ionic} width={20} height={20} />,
      },
    ],
  },
  {
    id: 4,
    name: "UI/UX",
    name2: "Designs",
    number: "04",
    title: "UI/UX & Designs",
    description:
      "User Interface (UI) and User Experience (UX) Design are both different aspects of the designing of a digital product and are crucial to the development. UI designers are focused on visual touchpoints that let users interact with a product, and UX Design is the part of the design that focuses on what the user feels when using that product. It concerns itself with the user’s overall experience and interaction using the product.",
    image: UIUXImage,
    icons: [
      {
        name: "Figma",
        icon: <img src={figma} width={40} height={40} />,
      },
      {
        name: "Canva",
        icon: <img src={canva} width={40} height={40} />,
      },
    ],
    mob_icons: [
      {
        name: "Figma",
        icon: <img src={figma} width={20} height={20} />,
      },
      {
        name: "Canva",
        icon: <img src={canva} width={20} height={20} />,
      },
    ],
  },
  {
    id: 5,
    name: "Testing & QA",
    name2: "",
    number: "05",
    title: "Testing & QA",
    description:
      "It is a process used to ensure that the end product which is developed conforms to the overall and scope-agreed expectations. It helps in verifying and assuring the quality of software before releasing it to customers. Testing aims to improve the quality and efficiency of a software development process. ",
    image: TestingImage,
  },
];
