import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TestimonialData from "../../assets/data/testimonial.json";
import TestimonialIcon from "../../assets/images/testimonial.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";

const Testimonial = () => {
  const navigationRef = React.useRef(null);

  return (
    <div className="testimonial " id="testimonial">
      <div className="testimonial-top-header">
        <img src={TestimonialIcon} width={25} height={25} />
        <div className="testimonial-fnt-lg">Testimonial</div>
      </div>
      <div className="heading">
        <div className="testimonial-headerfnt">
          Tech Excellence Unleashed: A Journey with Particle Infotech.
        </div>
        <p className="testimonial-headertext">
          Discover what our clients are saying about their partnership with us.
          Join the ranks of satisfied businesses who have experienced IT
          excellence firsthand.
        </p>
      </div>
      <div>
        <Swiper
          spaceBetween={30}
          slidesPerView={2}
          centeredSlides={true}
          parallax={true}
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          loop={true}
          onBeforeInit={(swiper) => {
            navigationRef.current = swiper;
          }}
          modules={[Autoplay, Pagination]}
          speed={1000}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          className=" testimonial-swiper"
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            767: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            991: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
          }}
        >
          {TestimonialData.map((slide, id) => (
            <SwiperSlide key={id}>
              <div
                className="testimonial-item"
                style={{
                  backgroundImage: `url(${slide.image})`,
                }}
              >
                <span className="testimonial-quoteIcon">
                  <img src={slide.imagetop} alt="logo" />
                </span>
                <div className="testimonial-cont-top"></div>
                <div className="testimonial-cont-bottom"></div>
                <span className="testimonial-quoteIcon-bottom">
                  <img src={slide.imagebottom} alt="logo" />
                </span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="setone setwo static commercial-container">
          <div
            onClick={() => navigationRef.current?.slideNext()}
            className="next-ctrl-1"
          >
            <i className="fas fa-chevron-right text-clr"></i>
          </div>
          <div
            onClick={() => navigationRef.current?.slidePrev()}
            className="prev-ctrl-1"
          >
            <i className="fas fa-chevron-left text-clr"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
