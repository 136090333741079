import React, { useEffect, useState } from "react";
import logo from "../../../assets/svg/logo.svg";
import { ImMenu, ImCross } from "react-icons/im";

const Header = ({ menuOpen, setMenuOpen }) => {
  const [scroll, setScroll] = useState(0);
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => setScroll(window.scrollY));
    return () =>
      window.removeEventListener("scroll", () => setScroll(window.scrollY));
  }, []);

  useEffect(() => {
    const isReached = scroll < 10;
    setShowNavbar(isReached);
  }, [scroll]);
  return (
    <>
      <nav className={showNavbar ? "header" : "header head-bg"}>
        <NavContent setMenuOpen={setMenuOpen} />
        <button
          className={`navBtn ${menuOpen ? "navBtnFixed" : ""}`}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? <ImCross /> : <ImMenu />}
        </button>
      </nav>
    </>
  );
};

const NavContent = ({ setMenuOpen }) => {
  const [tab, setTab] = useState(window.location.pathname);

  const clickMenuItem = (link) => {
    setTab(link);
    setMenuOpen(false);
  };
  return (
    <>
      <div className="logo">
        <a href="#home">
          <img
            src={logo}
            alt="particle_infotech"
            onClick={() => setMenuOpen(false)}
          />
        </a>
      </div>
      <div className="navItem">
        <a
          href="#home"
          onClick={() => clickMenuItem("/")}
          className={tab === "/" ? "active" : ""}
        >
          Home
        </a>
        <a
          href="#reasons"
          onClick={() => clickMenuItem("/reasons")}
          className={tab === "/reasons" ? "active" : ""}
        >
          Why choose us
        </a>
        <a
          href="#services"
          onClick={() => clickMenuItem("/services")}
          className={tab === "/services" ? "active" : ""}
        >
          Services
        </a>
        <a
          href="#work"
          onClick={() => clickMenuItem("/work")}
          className={tab === "/work" ? "active" : ""}
        >
          How we work
        </a>
        <a
          href="#portfolio"
          onClick={() => clickMenuItem("/portfolio")}
          className={tab === "/portfolio" ? "active" : ""}
        >
          Portfolio
        </a>
        <a
          href="#testimonial"
          onClick={() => clickMenuItem("/testimonial")}
          className={tab === "/testimonial" ? "active" : ""}
        >
          Testimonial
        </a>
      </div>
      <div className="contactBtn">
        <a href="#contactus" onClick={() => setMenuOpen(false)}>
          <button>
            <span className="notification" /> Get in Touch
          </button>
        </a>
      </div>
    </>
  );
};

export const HeaderPhone = ({ menuOpen, setMenuOpen }) => {
  return (
    <>
      <div className={`navPhone ${menuOpen ? "navHeaderDisplay" : ""}`}>
        <NavContent setMenuOpen={setMenuOpen} />
      </div>
    </>
  );
};
export default Header;
