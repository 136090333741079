import React from "react";
import logo from "../../../assets/svg/logo.svg";

const Footer = ({ serviceRef, slideHandler }) => {
  const scrollHandler = (slide) => {
    slideHandler(slide);
    serviceRef.current.scrollIntoView();
  };

  return (
    <div className="main-footer">
      <div className="footer" id="whychooseus">
        <div className="left">
          <img src={logo} alt="particle Infotech" width={250} height={100} />
          <p>
            At Particle Infotech, We believe in driving business growth through
            delivering high quality and sustainable solutions, and we are
            committed to staying ahead of the curve, keeping up with the latest
            trends, and using cutting edge technology to create unique and
            effective solutions for our clients. Explore our website to learn
            more about our services and how we can help your business thrive
          </p>
        </div>
        <div className="right">
          <div className="menuItem footercolumn">
            <h2>Menu</h2>
            <div className="item">
              <a href="/">Home</a>
              <a href="#reasons">Why choose us</a>
              <a href="#services">Services</a>
              <a href="#work">How we work</a>
              <a href="#portfolio">Portfolio</a>
              <a href="#testimonial">Testimonial</a>
            </div>
          </div>
          <div className="servicesItem footercolumn">
            <h2>Services</h2>
            <div className="item">
              <a onClick={scrollHandler.bind(this, 1)}>Front-End Development</a>
              <a onClick={scrollHandler.bind(this, 2)}>Back-End Development</a>
              <a onClick={scrollHandler.bind(this, 3)}>
                Mobile Apps Development
              </a>
              <a onClick={scrollHandler.bind(this, 4)}>UI/UX & Designs</a>
              <a onClick={scrollHandler.bind(this, 5)}>Testing & QA</a>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right">
        Copyright @ 2022-23 Particle Infotech LLP - All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
