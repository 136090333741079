import emailjs from "@emailjs/browser";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { AiFillMail } from "react-icons/ai";
import { BsPhoneFill } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import contactLogo from "../../assets/images/contact.png";
import contact from "../../assets/images/contact-us-100.png";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_fslrm7d", // Services ID
        "template_9ktjxtg", // Template ID
        form.current,
        "KyyQwUrKMQFp9BHbS" // Public Key
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          console.log(result);
          reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  return (
    <>
      <div className="border-wd"></div>
      <div className="contact" id="contactus">
        <div className="contact-top-header">
          <img src={contact} width={25} height={25} />
          <div className="contact-fnt-lg">Get In Touch</div>
        </div>
        <div className="heading">
          <div className="contact-headerfnt">
            Have questions or ready to embark on your IT journey with us?
          </div>

          <p className="contact-headertext">
            Contact our team of experts today, and let's transform your ideas
            into innovative solutions.
          </p>
        </div>

        <div className="contact_content">
          <div className="left">
            <form ref={form} onSubmit={handleSubmit(sendEmail)}>
              <div className="group">
                <label htmlFor="name">
                  NAME<span className="clr-form">&#42; </span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  className={`form-control ${errors.name && "invalid"}`}
                  {...register("name", {
                    required: "Name is required",
                  })}
                  onKeyUp={() => {
                    trigger("name");
                  }}
                />
                {errors.name && (
                  <small className="text-danger">{errors.name.message}</small>
                )}
              </div>
              <div className="group">
                <label htmlFor="email">
                  EMAIL<span className="clr-form">&#42; </span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  className={`form-control ${errors.email && "invalid"}`}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
                      message: "Invalid email address",
                    },
                  })}
                  onKeyUp={() => {
                    trigger("email");
                  }}
                />
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}
              </div>
              <div className="group">
                <label htmlFor="phone">
                  PHONE NUMBER<span className="clr-form">&#42; </span>
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Enter your phone number"
                  className={`form-control ${errors.phone && "invalid"}`}
                  {...register("phone", {
                    required: "Phone is required",
                    pattern: {
                      value:
                        /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/,
                      message: "Invalid Phone Number",
                    },
                  })}
                  onKeyUp={() => {
                    trigger("phone");
                  }}
                />
                {errors.phone && (
                  <small className="text-danger">{errors.phone.message}</small>
                )}
              </div>
              <div className="group">
                <label htmlFor="message">MESSAGE</label>
                <textarea
                  role="textbox"
                  type="text"
                  name="message"
                  id="message"
                  rows="3"
                  placeholder="Let us know your query here..."
                  className={`form-control ${errors.message ? "invalid" : ""}`}
                  onKeyUp={() => {
                    trigger("message");
                  }}
                ></textarea>
                {/* {errors.message && (
                <small className="text-danger">{errors.message.message}</small>
              )} */}
              </div>

              <button>SUBMIT</button>
            </form>
          </div>
          <div className="mid"></div>
          <div className="right">
            <div className="right-top-wd">
              <img
                src={contactLogo}
                alt="contactIcon"
                className="right-top-image"
              />
            </div>
            <div className="icon">
              <div>
                <AiFillMail width={400} />
                <p>business@particleinfotech.com</p>
              </div>
              <div>
                <BsPhoneFill />
                <p>+91 90172 68728</p>
              </div>
              <div>
                <ImLocation />
                <p>
                  Particle Infotech LLP, Plot No. 78, 2nd Floor, Industrial Area
                  Phase - 1, Chandigarh, India 160002
                </p>
              </div>
            </div>

            <div className="iconlogo">
              <h3 className="contact-follow">Follow us on </h3>
              <a
                href="https://www.facebook.com/profile.php?id=61552294230609"
                target="_blank"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.instagram.com/particle_infotech/"
                target="_blank"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.linkedin.com/company/particle-infotech-llp/"
                target="_blank"
              >
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
