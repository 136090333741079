import React, { useCallback, useEffect, useState } from "react";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import { useTypewriter, Cursor } from "react-simple-typewriter";

const Home = () => {
  const [text] = useTypewriter({
    words: [
      "Banking & Finance",
      "Healthcare",
      "Food & Beverage",
      "E-Commerce",
      "Education & Training",
      "Sports & Fitness",
      "Media & Entertainment",
      "Transport",
      "Travel & Hospitality",
    ],
    loop: {},
  });

  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  return (
    <>
      <div className="home" id="home">
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            fullScreen: {
              enable: false,
              zIndex: -1,
            },
            fpsLimit: 60,
            interactivity: {
              events: {
                onClick: {
                  enable: false,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "bubble",
                },
                modes: {
                  bubble: {
                    distance: 200,
                    size: 50,
                    opacity: 1,
                    duration: 2.1,
                  },
                },
              },
            },
            particles: {
              color: {
                value: "#8CDCD9",
              },
              links: {
                color: "$white_color",
                distance: 80,
                enable: false,
                opacity: 0.4,
                width: 0.8,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 100,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 1, max: 5 },
              },
            },
            detectRetina: true,
          }}
        />
        <div className="pd-right">
          <p>Custom Software Development Company</p>
          <h1 className="main-text main-head">Uniting Dreams Digitally...</h1>
          <p>
            <span className="industries-text">Industries We Serve: </span>
            <br />
            <span
              className="main-text main-text-animated"
              style={{ fontWeight: "bold", color: "#f16a6a", fontSize: "56px" }}
            >
              {text}
            </span>
            <span style={{ color: "#f16a6a" }}>
              <Cursor />
            </span>
          </p>

          <span className="home-content">
            <p>
              We're your digital transformation partner, an IT startup with a
              skilled team. We excel in web design, mobile app development, and
              more. We're passionate about using tech to transform businesses
              and enhance lives.
            </p>
          </span>
        </div>
        <div className="pd-left">
          <img src="/assets/banner/banner.png" />
        </div>
      </div>
    </>
  );
};

export default Home;
