import React, { useRef, useState } from "react";
import "./App.scss";
import Home from "./components/Home/Home";
import Header, { HeaderPhone } from "./components/Layout/Header/Header";
import Reasons from "./components/Reasons/Reasons";
import Services from "./components/Services/Services";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Layout/Footer/Footer";
import Portfolio from "./components/Portfolio/Portfolio";
import Testimonial from "./components/Testimonial/Testimonial";
import Work from "./components/Work/Work";
import Certified from "./components/Certified/certified";
const App = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const serviceRef = useRef(null);
  const [selectedService, setSelectedService] = useState(1);

  const selectedServiceHandler = (slide) => {
    setSelectedService(slide);
  };

  return (
    <>
      <HeaderPhone menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Home />
      <Reasons />
      <Services reference={serviceRef} selectedService={selectedService} />
      <Certified />
      <Work />
      <Portfolio />
      <Testimonial />
      <Contact />
      <Footer serviceRef={serviceRef} slideHandler={selectedServiceHandler} />
    </>
  );
};

export default App;
