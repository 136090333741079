import React from "react";
import deliver from "../../assets/svg/deliver.svg";
import design from "../../assets/svg/design.svg";
import develop from "../../assets/svg/develop.svg";
import discover from "../../assets/svg/discover.svg";
import Process from "../../assets/images/process-64.png";

const Work = () => {
  return (
    <div className="work" id="work">
      <div className="work-top-header">
        <img src={Process} width={25} height={25} />
        <div className="work-fnt-lg">How We Work</div>
      </div>
      <div className="heading">
        <div className="work-headerfnt">
          At Particle Infotech LLP, We simplify the complex.
        </div>
        <p className="work-headertext">
          We analyse, strategise, and implement cutting-edge IT solutions
          tailored to your unique needs. With our expertise, your business can
          thrive in the digital age.
        </p>
      </div>

      <div className="container-work">
        <div className="container-w-25">
          <div className="cont-icon cont-cynIcon cont-left-line ">
            <img src={discover} alt="icon" />
          </div>
          <div className="cont-text ">
            <h2>Discover</h2>
            <p className="detail-text">
              <span className="clr-blu">❝</span>
              We closely explore your vision, objectives, and challenges,
              defining project scope and requirements collaboratively. Our
              experts ensure a clear roadmap to success.
              <span className="clr-rd">❞</span>
            </p>
          </div>
        </div>
        <div className="container-w-25 reverse">
          <div className="cont-text ">
            <h2>Design</h2>
            <p className="detail-text">
              <span className="clr-blu">❝</span>
              We craft a goal-driven blueprint for your software, prioritizing
              user-centric design for an exceptional end product.
              <span className="clr-rd">❞</span>
            </p>
          </div>
          <div className="cont-icon cont-cynIcon cont-right-line ">
            <img src={design} alt="icon" />
          </div>
        </div>
        <div className="container-w-25">
          <div className="cont-icon cont-cynIcon cont-left-line">
            <img src={develop} alt="icon" />
          </div>
          <div className="cont-text ">
            <h2>Develop</h2>
            <p className="detail-text">
              <span className="clr-blu">❝</span>
              We bring your vision to life with seasoned developers, ensuring
              top-tier functionality, security, and performance through precise
              coding and testing.
              <span className="clr-rd">❞</span>
            </p>
          </div>
        </div>
        <div className="container-w-25 reverse">
          <div className="cont-text ">
            <h2>Deliver</h2>
            <p className="detail-text">
              <span className="clr-blu">❝</span>
              We rigorously test and seamlessly deploy your software, ready to
              empower your business, enhance user experiences, and drive
              results.
              <span className="clr-rd">❞</span>
            </p>
          </div>
          <div className="cont-icon cont-cynIcon">
            <img src={deliver} alt="icon" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Work;
