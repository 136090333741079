export const PORTFOLIO_CAROUSEL = [
  {
    img: "/assets/portfolio/umang.jpeg",
    heading: "UMANG",
    text: "UMANG: Your gateway to pan-India e-Gov services, connecting you with government services via app, web, SMS, and IVR channels.",
    text2:
      " We've developed a special tool for iPhones called an iOS custom BBPS framework. This tool makes it really simple for you to pay your bills, like your electricity or phone bills, and even school or college fees, all online. You can also top up your accounts with this framework, which means adding money to things like your mobile phone plan. We've designed it to work smoothly with PayU APIs, which are like the behind-the-scenes technology that makes these payments happen.",
  },
  {
    img: "/assets/portfolio/pet-dialog.png",
    heading: "Pet Dialog",
    text: "PetDialog Studies is an app for dog health research. If your vet's in the study, enter your code, fill out a survey about your dog's well-being, and discuss it with your vet during their checkup. Get handy reminders on your phone.",
    text2: "",
  },
  {
    img: "/assets/portfolio/rentee.png",
    heading: "Rentee",
    text: "Rentee is a rental marketplace where you can share your stuff. Rent out tools, electronics, cars, and more for extra cash. Traveling? Find what you need at your destination. No need to buy when you can rent!",
    text2: " Features we implemented:",
    list: [
      "List your items for rent",
      "Browse categories",
      "See top-rated items",
      "RealChat with owners",
      "Secure payments through Stripe",
      "Set your own pickup",
      "price and rules",
    ],
  },
];
