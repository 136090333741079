import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { PORTFOLIO_CAROUSEL } from "../../assets/data/Portfolio";
import portfolioIcon from "../../assets/images/portfolio-53.png";

const Portfolio = () => {
  return (
    <div className="main-portfolio">
      <div className="portfolio" id="portfolio">
        <div className="portfolio-top-header">
          <img src={portfolioIcon} width={25} height={25} />
          <div className="portfolio-fnt-lg">Portfolio</div>
        </div>
        <article className="heading">
          <div className="portfolio-headerfnt">
            Explore our curated portfolio of IT solutions.
          </div>
          <p>
            See how we've transformed businesses through innovative technology,
            driving success one project at a time
          </p>
        </article>

        <section>
          <Carousel
            showArrows={false}
            showIndicators={true}
            showStatus={false}
            showThumbs={false}
            autoPlay={true}
            interval={3000}
            infiniteLoop={true}
            stopOnHover={true}
            stopOnInteraction={true}
            transitionTime={500}
            emulateTouch={true}
            swipeScrollTolerance={50}
            preventMovementUntilSwipeScrollTolerance={true}
            onSwipeStart={(e) => e.stopPropagation()}

          >
            {PORTFOLIO_CAROUSEL.map((i, index) => (
              <div className="portfolioItem" key={index}>
                <div className="header-text">
                  <h3>{i.heading}</h3>
                  <p className="header-content">{i.text}</p>
                  <p className="header-content">{i.text2}</p>
                  {i.list &&
                    i.list.map((list, idx) => (
                      <ul className="list-text" key={idx}>
                        <li>{list}</li>
                      </ul>
                    ))}
                </div>
                <div className="crousal-box-top">
                  <div className="crousal-border">
                    <img
                      src={i.img}
                      alt={"Image " + index}
                      className="crousal-image"
                    />
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </section>
      </div>
    </div>
  );
};

export default Portfolio;
